import React, { createContext, useReducer } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const initialState = {
  selectedFilter: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER": {
      console.log("what value")
      console.log(action.value)
      return {
        selectedFilter: action.value,
      }
    }
    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state} key={"stateContext"}>
      <GlobalDispatchContext.Provider value={dispatch} key={"dispatchContext"}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
